<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variants: {
        primary: "bg-gradient-3",
        secondary: "bg-gradient-2",
      },
    };
  },
};
</script>

<template>
  <transition
    enter-active-class="animate__fadeIn animation_duration-200ms"
    leave-active-class="animate__fadeOut animation_duration-200ms"
  >
    <div
      v-if="modelValue"
      as="div"
      class="animate__animated fixed inset-0 z-20 flex max-h-screen justify-center overflow-auto bg-gray-700 bg-opacity-75 print:absolute print:block print:h-full print:overflow-visible"
      @mousedown="closeable && $emit('update:modelValue', false)"
    >
      <div
        class="relative my-auto h-fit w-full max-w-[560px] overflow-hidden rounded-2xl px-7 py-6 text-white"
        :class="variants[variant]"
        @mousedown.stop
      >
        <div class="pointer-events-none absolute left-0 top-0 h-full w-full">
          <img
            src="/images/bg-banner-1.svg"
            class="h-full w-full object-cover object-center"
            alt="Background"
          />
        </div>
        <div class="relative">
          <h2 class="mb-2 text-3xl font-medium">
            {{ title }}
          </h2>
          <div
            v-if="$slots && $slots.default"
            class="text-lg font-normal"
            :class="{ 'mb-4': !hideActions }"
          >
            <slot />
          </div>
          <template v-if="!hideActions">
            <p class="mb-7 text-lg font-semibold">
              Are you sure you want to proceed?
            </p>
            <div class="item-center flex justify-end gap-2">
              <RiseButton
                text="Cancel"
                variant="outline-white"
                size="l"
                @click="$emit('update:modelValue', false)"
              />
              <RiseButton
                text="Confirm"
                variant="white"
                size="l"
                @click="$emit('confirm')"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>
